.franchise-formats-page{
  width: 100%;
  height: 1000px;

  h1{
    text-align: center;
    margin: 40px 0;
    color: var(--text-color);
  }

 .format-selection-bg{
   width: 100%;
   height: 60px;
   background-color: var(--primary);


   .format-selection{
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: space-evenly;

       .format {
         cursor: pointer;
         padding: 5px;
         color: var(--text-color);
         text-align: center;
       }


     .active {
       padding: 0 20px;
       background: var(--primary-variant);
       box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
       border-radius: 25px;
       color: white;
     }
   }
 }

  .model-3d{
    padding-top: 20px;
  }
}


@media (max-width: 1600px) {
  .franchise-formats-page{
    height: 910px;
  }
}


@media (max-width: 1400px) {
  .franchise-formats-page{
    height: 800px;
  }
}


@media (max-width: 1200px) {
  .franchise-formats-page{
    height: 700px;

    h1{
      font-size: 1.7em;
    }

    .format-selection-bg{

      .format-selection{

        .format{
          font-size: 0.9em;
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .franchise-formats-page{
    //margin-top: 35px;
    height: 670px;

    h1{
      font-size: 1.4em;
      margin: 30px 0;
    }

    .format-selection-bg{
      height: 45px;

      .format-selection{

        .format{
          font-size: 0.7em;
        }

        .active{
          padding: 0 15px;
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .franchise-formats-page{
    height: 600px;
  }
}


@media (max-width: 500px) {
  .franchise-formats-page{
    height: 480px;

    h1{
      font-size: 1.2em;
      margin: 20px 0;
    }

    .format-selection-bg{
      height: 40px;

      .format-selection{

        .format{
          font-size: 0.6em;
        }

        .active{
          padding: 0 10px;
        }
      }
    }
  }
}


@media (max-width: 400px) {
  .franchise-formats-page{

    h1{
      font-size: 1.1em;
    }
  }
}