.popup-feedback{
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 50;

  .popup-main-content{
    width: 930px;
    height: 380px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--primary);
    border-radius: 30px;
    padding: 20px;

    h1{
      margin-top: -10px;
      color: var(--text-color);
    }

    .close-icon-1{
      width: 25px;
      cursor: pointer;
      position: relative;
      left: 48%;
      top: -8%;
    }

    .input-content{
      display: flex;
      flex-direction: row;
      justify-content: center;

      margin-top: 50px;

      .form-item{
        position: relative;
        margin: -25px 15px 0 15px;

        .input-item{
          height: 6vh;
          border-radius: 30px;
          outline: none;
          padding: 13px 13px 13px 35px;

          display: block;
          width: 100%;
          margin: auto;
        }

        .input-item::placeholder{
          color: var(--primary);
        }

        .input-item:focus{
          outline: none;
          background-size: 100% 100%, 100% 100%;
          transition-duration: 0.3s;
          box-shadow: none;
        }

        .label-item{
          position: absolute;
          top: 35%;
          left: 5%;
          transition: top 0.5s;
          padding: 0 16px;
          border-radius: 30px;
          color: var(--text-color);
          background-color: var(--primary);
        }


        .input-item:focus + .label-item,
        .input-item:not(:placeholder-shown) + .label-item, {
          top: -9px;
          font-size: 12px;
          padding: 0 10px;

        }
      }

      .form-item.error {

        .input-item {
          border-color: var(--secondary);
        }

        .label-item{
          color: var(--secondary);
        }
      }
    }

    .popup-button{
      width: 335px;
      height: 80px;
      border: none;
      border-radius: 20px;
      padding: 15px;
      margin-top: 40px;

      color: #ffffff;
      background: var(--primary-variant);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      transition: .2s;

      font-size: 25px;
      font-weight: 600;

      &:hover {
        background: var(--primary);
      }
    }

    .close-icon-2{
      width: 25px;
      cursor: pointer;
      position: relative;
      left: 48%;
      top: -34%;
    }

    .main-inscription{
      font-size: 24px;
      font-weight: 600;
      color: var(--text-color);
      padding: 0 80px;
    }
  }
}


@media (max-width: 1000px) {
  .popup-feedback{

    .popup-main-content{
      width: 750px;
      height: 300px;

      h1{
        font-size: 1.6em;
      }

      .close-icon-1{
        width: 20px;
        top: 0;
        left: 49%;
      }

      .input-content{

        .form-item{

        }
      }

      .popup-button{
        width: 260px;
        height: 65px;
        padding: 10px;
        font-size: 22px;
      }

      .close-icon-2{
        left: 48%;
        top: -30%;
      }

    }
  }
}


@media (max-width: 800px) {
  .popup-feedback{

    .popup-main-content{
      width: 500px;
      height: 250px;

      h1{
        font-size: 1.2em;
      }

      .close-icon-1{
        width: 17px;
        top: -2%;
        left: 49%;
      }

      .input-content{

        .form-item{
          margin: -25px 8px 0 8px;

          .input-item{
            height: 5vh;
          }

        }
      }

      .popup-button{
        width: 230px;
        height: 60px;
        font-size: 20px;
        margin-top: 30px;
      }

      .close-icon-2{
        width: 22px;
        left: 47%;
        top: -23%;
      }

      .main-inscription{
        font-size: 21px;
        padding: 0 20px 0 40px;
      }
    }
  }
}


@media (max-width: 500px) {
  .popup-feedback{

    .popup-main-content{
      width: 320px;
      height: 220px;

      h1{
        font-size: 0.8em;
        margin: 0;
      }

      .close-icon-1{
        width: 14px;
        top: -5%;
        left: 51%;
      }

      .input-content{

        .form-item{
          margin: -25px 8px 0 8px;

          .input-item{
            width: 120px;
          }

          .label-item{
            font-size: 12px;
            max-width: 80px;
            padding: 0 10px;
          }

          .input-item:focus + .label-item,
          .input-item:not(:placeholder-shown) + .label-item, {
            top: -5px;
            font-size: 9px;
          }
        }
      }

      .popup-button{
        width: 160px;
        height: 45px;
        font-size: 14px;
        margin-top: 20px;
      }

      .close-icon-2{
        width: 19px;
        left: 46%;
        top: -12%;
      }

      .main-inscription{
        font-size: 17px;
        padding: 0 30px 0 40px;
      }
    }
  }
}