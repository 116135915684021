.shop-stopper-page{
  width: 100%;
  padding: 100px 0 100px;

  .main-content{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;

    .message{
      width: 280px;
      background-image: url("../../assets/images/dialog.png");
      background-position: center top;
      background-size: contain;
      background-repeat: no-repeat;

      .text-message{
        max-width: 210px;
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--text-color);
        text-align: center;
        position: relative;
        top: 100px;
        left: 11%;
      }
    }
    .racoon{
      width: 280px;
      margin-top: 100px;

      img {
        width: 100%;
      }
    }
  }
}


@media (max-width: 600px) {
  .shop-stopper-page{
    padding: 100px 0 0;

    .main-content{

      .message{
        width: 180px;
        margin-top: 80px;

        .text-message{
          max-width: 160px;
          font-size: 1.1rem;
          top: 60px;
          left: 11px;
        }
      }
      .racoon{
        width: 180px;
        margin-top: 180px;
      }
    }
  }
}


@media (max-width: 380px) {
  .shop-stopper-page{


    .main-content{
      height: 350px;

      .message{
        width: 150px;
        margin-top: 0;

        .text-message{
          max-width: 130px;
          font-size: 0.9rem;
          top: 50px;
          left: 11px;
        }
      }
      .racoon{
        width: 150px;
        margin-top: 80px;
      }
    }
  }
}