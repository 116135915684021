.footer {
  width: 100%;
  padding: 0 2rem 1rem 2rem;
  margin-top: auto;

  background: var(--primary);

  .container {
    display: flex;
    justify-content: space-around;


    .block-title {
      margin-bottom: 1rem;

      font-size: 1.2rem;
    }

    .block-title, li {
      color: var(--text-color);
    }

    .nav-list, .contacts, .social-networks {
      margin-top: 2rem;
    }

    .nav-list {

      li {
        margin-bottom: 0.4rem;

        list-style-type: none;
        font-weight: 500;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .contacts {
      display: flex;
      flex-direction: column;
      align-items: center;

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          margin-bottom: 0.5rem;

          font-weight: 500;
          font-size: 0.9rem;
          list-style-type: none;
          text-align: center;
        }
      }
    }

    .social-networks {

      .block-title {
        text-align: center;
        margin-bottom: 35px;
      }

      .social-networks-icons {
        display: flex;
        justify-content: center;


        a{
          width: 35px;
          height: 35px;
          margin: 0 14px;
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;

          cursor: pointer;
        }
      }
    }
  }
}

div[theme="dark"] .footer svg{
  fill: #cbcbcb;
}


@media (max-width: 800px) {
  .footer {
    padding: 0 0 1rem 0;

    .container {

      .social-networks {
        margin-top: 0.8rem;
        margin-bottom: 0;

        .block-title {
          margin-bottom: 18px;
        }

        .social-networks-icons {


          a{
            width: 35px;
            height: 35px;
            margin: 0 14px;
          }

          svg {
          }
        }
      }
    }
  }
}