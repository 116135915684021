.popup-field-check-container{
  width: 600px;
  height: 60px;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-290px);
  display: flex;
  justify-content: center;
  z-index: 51;

  background-color: var(--primary);
  border-radius: 20px;
  box-shadow:0 0 7px rgba(0, 0, 0, 0.6);



    img{
      width: 16px;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 22px;
    }

    .main-text{
      font-weight: 550;
      font-size: 1.1rem;
      text-align: center;
      color: var(--text-color);

      margin-top: 20px;
      padding-right: 15px;
    }

  .main-text.active{
    color: var(--secondary);
  }
}


@media (max-width: 600px) {
  .popup-field-check-container{
    width: 300px;
    height: 70px;
    transform: translateX(-150px);

      img{
        width: 14px;
       top: 10px;
        right: 8px;
      }

      .main-text{
        font-size: 0.9rem;
        padding-right: 10px;

      }
    }
}