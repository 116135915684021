li {
  list-style-type: none;
}


.slide2-container {
  width: 100%;
  margin: 0 0 80px 260px;


  .slide2-wrapper {
    width: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;


    .phone-wrapper {
      width: 24%;


      .phone-screen-bonus {
        width: 100%;


      }
    }

    .description-wrapper {
      width: 35%;
      margin-left: 10%;

      color: var(--text-color);

      .first-description p {
        font-size: 19px;
        font-weight: 500;
      }

        .first-description h3 {
          margin-bottom: 20px;
          font-size: 1.3rem;
        }

      .second-description li{
        font-size: 19px;
        font-weight: 500;
      }

      .second-description h3{
        margin-top: 30px;
        font-size: 1.3rem;
      }

      .three-description li{
        font-size: 19px;
        font-weight: 500;
      }

      .three-description h3{
        margin-top: 30px;
        font-size: 1.3rem;
      }
    }
  }
}


@media (max-width: 1350px) {
  .slide2-container {
    margin: 0 20px 80px 110px;

    .slide2-wrapper {

      .phone-wrapper {
        width: 35%;
      }

      .description-wrapper {

        .first-description p {
          font-size: 18px;
          font-weight: 450;
        }

        .first-description h3 {
          margin-bottom: 20px;
          font-size: 1.2rem;
        }

        .second-description li{
          font-size: 18px;
          font-weight: 450;
        }

        .second-description h3{
          margin-top: 20px;
          font-size: 1.2rem;
        }

        .three-description li{
          font-size: 18px;
          font-weight: 450;
        }

        .three-description h3{
          margin-top: 20px;
          font-size: 1.2rem;
        }
      }
    }
  }
}


@media (max-width: 1050px) {
  .slide2-container {
    margin: 0;


    .slide2-wrapper {
      display: flex;
      flex-direction: column;
      min-height: auto;


      .phone-wrapper {
        width: 40%;
        margin-bottom: 20px;
      }

      .description-wrapper {
        margin: 40px 0 30px 0;
        width: 70%;
      }
    }
  }
}


@media (max-width: 800px) {
  .slide2-container {

    .slide2-wrapper {

      .phone-wrapper {
        width: 55%;

        .phone-screen-bonus {
        }
      }

      .description-wrapper {
        width: 80%;

        .first-description p {
          font-size: 16px;
        }

        .first-description h3 {
          font-size: 1.08rem;
        }

        .second-description li{
          font-size: 16px;
        }

        .second-description h3{
          font-size: 1.08rem;
        }

        .three-description li{
          font-size: 16px;
        }

        .three-description h3{
          font-size: 1.08rem;
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .slide2-container {

    .slide2-wrapper {

      .phone-wrapper {
        width: 80%;
        margin-bottom: 0;

        .phone-screen-bonus {
        }

      }

      .description-wrapper {
        width: 100%;
        max-width: 320px;
        margin-bottom: 20px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slide2-container {

    .slide2-wrapper {

      .phone-wrapper {
        width: 90%;

        .phone-screen-bonus {
        }
      }

      .description-wrapper {
        max-width: 200px;
      }
    }
  }
}




