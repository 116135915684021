.about_mobile_app {
  width: 100%;
  max-width: 100%;
  padding-top: 40px;

  .title {
    width: 100%;
    margin: 45px 0;

    font-size: 1.6rem;
    text-align: center;
    color: var(--text-color);
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;


    .google-play {
      width: 100%;
      max-width: 200px;
    }

    .app-store {
      width: 100%;
      max-width: 200px;

    }

    a{
      width: 200px;
      height: 60px;
      margin: 0 20px;
    }
  }
}


@media (max-width: 800px) {
  .about_mobile_app{
    padding-top: 0;
  }
}


@media (max-width: 500px) {
  .about_mobile_app {
    padding: 0 0 20px 0;

    .title {
      font-size: 1.3rem;
    }

    .buttons {
      display: none;
    }
  }
}