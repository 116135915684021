li {
  list-style-type: none;
}

.slide4-container {
  width: 100%;
  margin: 0 0 80px 260px;


  .slide4-wrapper {
    width: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;

    .phone-slide4 {
      width: 24%;


      .phone-screen-awards {
        width: 100%;


      }
    }

    .slide4-description {
      width: 35%;
      margin-left: 10%;

      color: var(--text-color);

      .slide4-firstDescription {
        margin-bottom: 20px;

        h3 {
          margin-bottom: 20px;
          font-size: 1.3rem;
        }

        p{
          font-size: 19px;
          font-weight: 500;
        }

      }

      .slide4-secondDescription {

        h3 {
          margin-bottom: 20px;
          font-size: 1.3rem;
        }

        p{
          font-size: 19px;
          font-weight: 500;
        }

        li{
          font-size: 19px;
          font-weight: 500;
        }
      }
    }
  }
}


@media (max-width: 1350px) {
  .slide4-container {
    margin: 0 20px 80px 110px;

    .slide4-wrapper {

      .phone-slide4 {
        width: 35%;
      }

      .slide4-description {

        .slide4-firstDescription {

          h3 {
            margin-bottom: 20px;
            font-size: 1.2rem;
          }

          p {
            font-size: 18px;
            font-weight: 450;
          }

        }

        .slide4-secondDescription {

          h3 {
            margin-bottom: 20px;
            font-size: 1.2rem;
          }

          p {
            font-size: 18px;
            font-weight: 450;
          }

          li {
            font-size: 18px;
            font-weight: 450;
          }
        }
      }
    }
  }
}



@media (max-width: 1050px) {
  .slide4-container {
    margin: 0;

    .slide4-wrapper {
      display: flex;
      flex-direction: column;
      min-height: auto;


      .phone-slide4 {
        width: 40%;
        margin-bottom: 20px;


        .phone-screen-awards {
        }
      }

      .slide4-description {
        margin: 40px 0 30px 0;
        width: 50%;
      }
    }
  }
}


@media (max-width: 800px) {
  .slide4-container {

    .slide4-wrapper {

      .phone-slide4 {
        width: 55%;
      }

      .slide4-description {
        width: 80%;

        .slide4-firstDescription {

          h3 {
            font-size: 1.08rem;
          }

          p {
            font-size: 16px;
          }

        }

        .slide4-secondDescription {

          h3 {
            font-size: 1.08rem;
          }

          p {
            font-size: 16px;
          }

          li {
            font-size: 16px;
          }
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .slide4-container {

    .slide4-wrapper {

      .phone-slide4 {
        width: 80%;
        margin-bottom: 0;

        .phone-screen-awards {
        }

      }

      .slide4-description {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slide4-container {

    .slide4-wrapper {

      .phone-slide4 {
        width: 90%;

        .phone-screen-awards {
        }
      }

      .slide4-description {
        max-width: 200px;
      }
    }
  }
}