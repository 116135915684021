.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);





  .container {
    display: flex;
    align-items: center;



    .header-wrapper {
      width: 100%;
      height: 85px;
      display: flex;
      align-items: center;
      padding: 0.3rem 3rem;
      transition: height 0.5s;


      background-color: var(--primary);


      .logo {
        img {
          height: 3.8rem;
        }

        margin-left: 4%;
        margin-top: 4px;
      }

      .menu {
        margin-left: 5%;

        ul {
          display: flex;

          li {
            list-style-type: none;
            margin-left: 9%;


            span {
              color: var(--text-color);
              font-weight: 600;
              font-size: 1.5rem;
              cursor: pointer;

              margin-right: 25px;

              a{
                text-decoration: none;
                color: var(--text-color);
              }

              &.active {
                color: var(--secondary);
              }
            }
          }
        }
      }

      .right-block {
        margin-left: auto;

        display: flex;
        align-items: center;

        .language-switcher {
          margin-right: 30px;
        }
      }
    }

    .header-wrapper.min {
      height: 50px;


      .logo {
        display: none;
      }
    }
  }
}


.language-switcher {
  display: flex;
  align-items: center;

  span {
    margin: 0 3px;
    width: 35px;
    height: 35px;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;


    color: var(--text-color);
    font-size: 15px;
    cursor: pointer;
    font-weight: 600;

    &.active {
      border: 1px solid var(--secondary);

      color: var(--secondary);
    }
  }
}


@media (max-width: 1000px) {
  .header {

    .container {

      .header-wrapper {
        padding: 0.3rem 2.5rem;

        .logo {
          img {
            height: 3.7rem;
          }

          margin-left: 2%;

        }

        .menu {

          ul {

            li {
              margin-left: 5%;

              span {
                font-size: 1.4rem;
              }
            }
          }
        }

      }

      .header-wrapper.min {
        padding: 0.3rem 2.5rem;

        .menu {

          ul {

            li {
              margin-left: 5%;

              span {
                font-size: 1.4rem;
              }
            }
          }
        }

      }
    }
  }
}


@media (max-width: 850px) {
  .header {

    .container {

      .header-wrapper {
        padding: 0.3rem 2.5rem;

        .logo {
          img {
            height: 3.2rem;
          }

          margin-left: 2%;
        }

        .menu {

          ul {

            li {
              margin-left: 4%;

              span {
                font-size: 1.2rem;
              }
            }
          }
        }

        .right-block {

          .language-switcher {

            span {
              width: 33px;
              height: 33px;

              font-size: 12px;
            }
          }
        }
      }

      .header-wrapper.min {
        padding: 0.3rem 2.5rem;

        .menu {

          ul {

            li {
              margin-left: 4%;

              span {
                font-size: 1.2rem;
              }
            }
          }
        }

        .right-block {

          .language-switcher {

            span {
              width: 33px;
              height: 33px;

              font-size: 12px;
            }
          }
        }
      }
    }
  }

}


@media (max-width: 800px) {
  .header {
    height: auto;

    .container {
      background-color: var(--primary);

      .button-menu-wrapper {
        margin-left: 10px;
        padding: 10px 10px;
        cursor: pointer;

        svg{
          fill: var(--text-color)
        }
      }

      .white-bg {
        position: fixed;
        top: 0;
        right: 0;

        visibility: hidden;
        width: 100vw;
        height: 100vh;

        opacity: 0;
        background: #000;
        transition: 0.1s;

        &.active {
          visibility: visible;
          opacity: 0.5;
        }
      }

      .navigation {
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;

        width: 100vw;
        max-width: 280px;
        height: 100vh;
        transform: translate(-100%);

        overflow: auto;

        transition: .2s;
        background: var(--primary);

        &.open {
          transform: translate(0);
        }

        .close-nav-btn {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          cursor: pointer;

          background: var(--primary-variant);

          font-weight: 600;
          color: white;
        }

        .menu {
          margin-left: 15px;
          padding: 20px 0;

          ul :nth-child(-n + 3) {
            margin-bottom: 14px;
          }

          ul :nth-child(3) {
            margin-bottom: 30px;
          }

          ul :nth-child(n + 4) {
            margin-bottom: 10px;
          }

          ul {
            display: flex;
            flex-direction: column;


            li {
              list-style-type: none;
              align-self: flex-start;

              span {
                font-weight: 400;
                font-size: 1rem;
                cursor: pointer;
                color: var(--text-color);

                a{
                  text-decoration: none;
                  color: var(--text-color);
                }

                strong {
                  font-weight: 600;
                }

                &.active {
                  color: var(--secondary);
                }
              }
            }
          }
        }

        .switch-theme, .switch-language {
          display: flex;
          align-items: center;
          padding: 10px 15px;

          > span {
            width: 60%;

            font-weight: 600;
            font-size: 1.1rem;
            color: var(--text-color);
          }

          > div {
            display: flex;
            justify-content: center;
            width: 40%;
          }
        }

        .social-networks {
          margin-top: 30px;
          margin-left: 15px;

          .block-title {
            margin-bottom: 10px;
            color: var(--text-color);
          }

          .social-networks-icons :nth-child(1) {
            margin-left: 0;
          }

          .social-networks-icons a {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }

          .social-networks-icons {
            display: flex;


            svg {
              width: 2.2rem;
              height: 2.2rem;

              cursor: pointer;
            }
          }
        }

        .about-mobile-app {
          display: flex;
          flex-direction: column;
          margin-top: 35px;
          margin-left: 15px;

          .block-title {
            margin-bottom: 15px;
            color: var(--text-color);
          }

          .google-play {
            width: 200px;
          }

          .app-store {
            width: 200px;
            margin: 0 0 10px 0;
          }
          a{
            width: 200px;
            height: 60px;
            margin-bottom: 10px;
          }
        }

        .contacts {
          margin: 30px 0 60px 15px;

          .block-title {
            margin-bottom: 15px;
            color: var(--text-color);
          }

          ul :nth-child(2) {
            margin-bottom: 15px;
          }

          ul {
            li {
              margin-bottom: 3px;

              list-style-type: none;
              color: var(--text-color);
            }
          }
        }
      }

      .logo {
        margin-left: auto;
        padding: 10px 20px 5px 0;

        img {
          height: 45px;
        }
      }
    }
  }
}

div[theme="dark"] .header svg {
  fill: #cbcbcb;
}

