.page-aboutUs{
  width: 100%;
  padding-top: 90px;
  padding-bottom: 50px;

  .title{
    text-align: center;
    margin-bottom: 40px;
    color: var(--text-color);
  }

  .part-one{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 15px;

    .part-one-description{
      width: 40%;
      margin-right: 8%;

      font-weight: 600;
      font-size: 24px;
      color: var(--text-color);
    }

    .part-one-image{
      width: 46%;
      height: 16%;

      border-radius: 35px 0 0 35px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
  }
  .part-two{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 15px;

    .part-two-description{
      width: 40%;
      margin-left: 8%;

      font-weight: 600;
      font-size: 24px;
      color: var(--text-color);
    }

    .part-two-image{
      width: 46%;
      height: 16%;

      border-radius: 0 35px 35px 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
  }

  .part-three{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .description-wrapper{
      width: 40%;
      margin-right: 8%;

      .part-three-description1{
        margin-bottom: 20px;

        width: 100%;
        font-weight: 600;
        font-size: 24px;
        color: var(--text-color);
      }

      .part-three-description2{
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        color: var(--text-color);
      }
    }

    .part-three-image{
      width: 46%;
      height: 16%;

      border-radius: 35px 0 0 35px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
  }
}


@media (max-width: 1350px) {
  .page-aboutUs{

    .part-one{

      .part-one-description{
        font-size: 20px;
      }

      .part-one-image{
      }
    }
    .part-two{

      .part-two-description{
        font-size: 20px;
      }
    }

    .part-three{

      .description-wrapper{

        .part-three-description1{
          font-size: 20px;
        }

        .part-three-description2{
          font-size: 20px;
        }
      }
    }
  }
}



@media (max-width: 1100px) {
  .page-aboutUs{
    padding-top: 60px;

    .part-one{

      .part-one-description{
        font-size: 18px;
      }
    }
    .part-two{

      .part-two-description{
        font-size: 18px;
      }
    }

    .part-three{

      .description-wrapper{

        .part-three-description1{
          font-size: 18px;
        }

        .part-three-description2{
          font-size: 18px;
        }
      }
    }
  }
}


@media (max-width: 950px) {
.page-aboutUs{
  padding-top: 40px;
  padding-bottom: 30px;

  .title{
    font-size: 13px;
  }

  .part-one{

    .part-one-description{
      font-size: 16px;
    }
  }
  .part-two{

    .part-two-image{

    }

    .part-two-description{
      font-size: 16px;
    }
  }

  .part-three{

    .description-wrapper{

      .part-three-description1{
        font-size: 16px;
        margin-bottom: 10px;
      }

      .part-three-description2{
        font-size: 16px;
      }
    }
  }
}
}


@media (max-width: 820px) {
  .page-aboutUs{

    .title{
      font-size: 11px;
    }

    .part-one{

      .part-one-description{
        font-size: 14px;
      }
    }
    .part-two{

      .part-two-description{
        font-size: 14px;
      }
    }

    .part-three{

      .description-wrapper{

        .part-three-description1{
          font-size: 14px;
        }

        .part-three-description2{
          font-size: 14px;
        }
      }
    }
  }
}


@media (max-width: 750px) {
  .page-aboutUs{
    padding-bottom: 60px;

    .title{
      font-size: 14px;
    }

    .part-one{
      display: flex;
      flex-direction: column;

      margin-bottom: 0;

      .part-one-description{
        width: 90%;
        margin: 25px 0 70px 0;
        order: 1;

        font-size: 18px;
      }

      .part-one-image{
        width: 90%;
        border-radius: 35px 35px;
      }
    }
    .part-two{
      display: flex;
      flex-direction: column;

      margin-bottom: 0;

      .part-two-image{
        width: 90%;
        border-radius: 35px 35px;
      }

      .part-two-description{
        width: 90%;
        margin: 25px 0 70px 0;

        font-size: 18px;
      }
    }

    .part-three{
      display: flex;
      flex-direction: column;

      .description-wrapper{
        width: 90%;
        margin: 25px 0 0 0;
        order: 1;

        .part-three-description1{
          font-size: 18px;
        }

        .part-three-description2{
          font-size: 18px;
        }
      }

      .part-three-image{
        width: 90%;
        border-radius: 35px 35px;
      }
    }
  }
}


@media (max-width: 500px) {
  .page-aboutUs{
    padding-bottom: 70px;

    .title{
      font-size: 11px;
    }

    .part-one{

      .part-one-description{
        font-size: 15px;
      }
    }
    .part-two{

      .part-two-description{
        font-size: 15px;
      }
    }

    .part-three{

      .description-wrapper{

        .part-three-description1{
          font-size: 15px;
        }

        .part-three-description2{
          font-size: 15px;
        }
      }
    }
  }
}
