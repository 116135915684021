.select {
  position: relative;
  width: fit-content;

  &.show-options {
    input {
      position: relative;
      z-index: 20;
    }

    .options {
      visibility: visible;
      background-color: var(--primary);
    }

    .row {
      z-index: 20;

      transform: rotate(180deg) translate(0, 5px);
      transform-origin: center;
    }
  }

  input {
    cursor: pointer;
    caret-color: transparent;
  }

  .options {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;

    width: 100%;
    max-height: 30vh;
    visibility: hidden;
    transform: translate(0, calc(100% + 2px));
    border: 1px solid #202020;
    border-radius: 28px;
    overflow: auto;

    background: #E9E9E9;



    .option {
      width: 100%;
      padding: 10px 15px;
      border-bottom: 1px solid #202020;

      word-wrap: break-word;
      cursor: pointer;
      list-style-type: none;
      font-weight: 500;

      color: var(--text-color);

      &:last-child {
        border-bottom: 0;
        padding-bottom: 20px;
      }
      &:first-child {
        padding-top: 20px;
      }
    }
  }

  .row {
    position: absolute;
    top: 50%;
    right: 15px;

    height: 10px;
    width: 10px;
    transform: translate(0, -50%);
    cursor: pointer;

    path {
      fill: var(--text-color);
    }
  }
}