html, body, root{
  height: 100%;
}

#root{
  height: 100%
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  background: var(--background);

  .bg {
    height: 100%;
    flex-grow: 1;
    background: url("assets/images/bg.png");

    .content{
      height: 100%;


      .container {
        padding-top: 50px;
      }
    }
  }
}