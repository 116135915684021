li {
  list-style-type: none;
}

.slide6-container {
  width: 100%;
  margin: 0 0 80px 260px;

  .slide6-wrapper {
    width: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;

    .phone-slide6 {
      width: 24%;

      .phone-screen-statistic {
        width: 100%;
      }
    }

    .slide6-description {
      width: 40%;
      margin-left: 10%;

      color: var(--text-color);

      .slide6-firstDescription {

        h3 {
          margin-bottom: 20px;
          font-size: 1.3rem;
        }

        p {
          font-size: 19px;
          font-weight: 500;
        }
      }

      .slide6-secondDescription {

        p {
          font-size: 19px;
          font-weight: 500;
        }

        li {
          font-size: 19px;
          font-weight: 500;
        }
      }
    }
  }
}


@media (max-width: 1350px) {
  .slide6-container {
    margin: 0 20px 80px 110px;

    .slide6-wrapper {

      .phone-slide6 {
        width: 35%;
      }

      .slide6-description {

        .slide6-firstDescription {

          h3 {
            font-size: 1.2rem;
          }

          p {
            font-size: 18px;
            font-weight: 450;
          }
        }

        .slide6-secondDescription {

          p {
            font-size: 18px;
            font-weight: 450;
          }

          li {
            font-size: 18px;
            font-weight: 450;
          }
        }
      }
    }
  }
}


@media (max-width: 1050px) {
  .slide6-container {
    margin: 0;

    .slide6-wrapper {
      display: flex;
      flex-direction: column;
      min-height: auto;


      .phone-slide6 {
        width: 40%;
        margin-bottom: 20px;
      }

      .slide6-description {
        margin: 40px 0 30px 0;
        width: 50%;
      }
    }
  }
}


@media (max-width: 800px) {
  .slide6-container {

    .slide6-wrapper {

      .phone-slide6 {
        width: 55%;
      }

      .slide6-description {
        width: 80%;

        .slide6-firstDescription {

          h3 {
            font-size: 1.08rem;
          }

          p {
            font-size: 16px;
          }
        }

        .slide6-secondDescription {

          p {
            font-size: 16px;
          }

          li {
            font-size: 16px;
          }
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .slide6-container {

    .slide6-wrapper {

      .phone-slide6 {
        width: 80%;
        margin-bottom: 0;
      }

      .slide6-description {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slide6-container {

    .slide6-wrapper {

      .phone-slide6 {
        width: 90%;
      }

      .slide6-description {
        max-width: 200px;

      }
    }
  }
}