.model-cup-page {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px 0 150px;

  .description-3d-model {
    font-size: 1.3rem;
    font-weight: 600;
    width: 30%;
    margin-right: 3%;
    color: var(--text-color);
  }

  .model-cup-3d {
    width: 65%;
    height: 100%;
    margin-left: 3%;

    &>div{   // Щоб фотографія не відображалася поверх хедера
      z-index: 99;
    }

    canvas + div{
      width: 100%;
      height: 100%;
    }

    .img-cup{
      width: 100%;
      height: 100%;

      img{
        max-width: 100%;
        max-height: 100%;
        margin-left: 10%;

      }
    }
  }
}


@media (max-width: 1600px) {
  .model-cup-page{
    height: 810px;

    .description-3d-model {
      font-size: 1.2rem;
    }
  }
}


@media (max-width: 1400px) {
  .model-cup-page{
    height: 700px;

    .description-3d-model {
      font-size: 1.1rem;
    }
  }
}


@media (max-width: 1200px) {
  .model-cup-page{
    height: 600px;
    padding-left: 100px;


    .description-3d-model {
      font-size: 1rem;
    }
  }
}


@media (max-width: 1000px) {
  .model-cup-page{
    flex-direction: column-reverse;
    padding: 0 50px;

    .description-3d-model {
      font-size: 1rem;
      width: 70%;
      height: 14%;
      margin-right: 0;
    }

    .model-cup-3d{
      width: 100%;
      height: 86%;

      .img-cup{

        img{
          margin-left: 28%;
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .model-cup-page{

    .model-cup-3d{

      .img-cup{

        img{
          margin-left: 20%;
        }
      }
    }
  }
}


@media (max-width: 700px) {
  .model-cup-page{

    .model-cup-3d{

      .img-cup{

        img{
          margin-left: 15%;
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .model-cup-page{
    height: 500px;

    .description-3d-model {
      width: 90%;
    }
  }
}


@media (max-width: 500px) {
  .model-cup-page{
    height: 380px;
    padding: 0;

    .description-3d-model {
      width: 80%;
      font-size: 0.8rem;
    }

    .model-cup-3d{

      .img-cup{

        img{
          margin-left: 22%;
        }
      }
    }
  }
}


@media (max-width: 400px) {
  .model-cup-page{

    .model-cup-3d{

      .img-cup{

        img{
          margin-left: 17%;
        }
      }
    }
  }
}