li {
  list-style-type: none;
}


.slide5-container {
  width: 100%;
  margin: 0 0 80px 260px;

  .slide5-wrapper {
    width: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;

    .phone-slide5 {
      width: 24%;

      .phone-screen-cashback {
        width: 100%;

      }
    }

    .slide5-description {
      width: 40%;
      margin-left: 10%;

      color: var(--text-color);

      .slide5-firstDescription {
        margin-bottom: 20px;

        h3 {
          margin-bottom: 20px;
          font-size: 1.3rem;
        }

        p{
          font-size: 19px;
          font-weight: 500;
        }
      }

      .slide5-secondDescription {
        margin-bottom: 20px;

        p{
          font-size: 19px;
          font-weight: 500;
        }
      }

      .slide5-threeDescription {

        p{
          font-size: 19px;
          font-weight: 500;
        }
      }
    }
  }
}


@media (max-width: 1350px) {
  .slide5-container {
    margin: 0 20px 80px 110px;

    .slide5-wrapper {

      .phone-slide5 {
        width: 35%;
      }

      .slide5-description {

        .slide5-firstDescription {

          h3 {
            font-size: 1.2rem;
          }

          p{
            font-size: 18px;
            font-weight: 450;
          }
        }

        .slide5-secondDescription {

          p{
            font-size: 18px;
            font-weight: 450;
          }
        }

        .slide5-threeDescription {

          p{
            font-size: 18px;
            font-weight: 450;
          }
        }
      }
    }
  }
}


@media (max-width: 1050px) {
  .slide5-container {
    margin: 0;

    .slide5-wrapper {
      display: flex;
      flex-direction: column;
      min-height: auto;


      .phone-slide5 {
        width: 40%;
        margin-bottom: 20px;


        .phone-screen-cashback {
        }
      }

      .slide5-description {
        margin: 40px 0 30px 0;
        width: 50%;
      }
    }
  }
}


@media (max-width: 800px) {
  .slide5-container {

    .slide5-wrapper {

      .phone-slide5 {
        width: 55%;

      }

      .slide5-description {
        width: 80%;

        .slide5-firstDescription {

          h3 {
            font-size: 1.08rem;
          }

          p{
            font-size: 16px;
          }
        }

        .slide5-secondDescription {

          p{
            font-size: 16px;
          }
        }

        .slide5-threeDescription {

          p{
            font-size: 16px;
          }
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .slide5-container {

    .slide5-wrapper {

      .phone-slide5 {
        width: 80%;
        margin-bottom: 0;

        .phone-screen-cashback {
        }

      }

      .slide5-description {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slide5-container {

    .slide5-wrapper {

      .phone-slide5 {
        width: 90%;

        .phone-screen-cashback {
        }
      }

      .slide5-description {
        max-width: 200px;

      }
    }
  }
}