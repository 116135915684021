.feedback-page{
  width: 100%;
  margin-top: 100px;
  padding-bottom: 60px;

  .title-callback-page{
    text-align: center;
    color: var(--text-color);
    margin: 0;
  }

  .feedback-main-content{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .feedback-button{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;

      .feedback-button-icon{
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
      }

      .feedback-button-description-one{
        font-weight: 550;
        font-size: 20px;
        margin-top: 15px;
        color: var(--text-color);
        text-align: center;
        cursor: pointer;
      }

      .feedback-button-description-two{
        font-weight: 550;
        font-size: 20px;
        margin-top: 10px;
        color: var(--text-color);
        text-align: center;
        cursor: pointer;
      }

      .button{
        text-decoration: none;
      }
    }
  }
}


@media (max-width: 1200px) {
  .feedback-page{

    .title-callback-page{
      font-size: 1.7em;
    }
  }
}


@media (max-width: 800px) {
  .feedback-page{

    .title-callback-page{
      font-size: 1.4em;
    }

    .feedback-main-content{

      .feedback-button{

        .feedback-button-icon{
          width: 120px;
        }

        .feedback-button-description-one{
        font-size: 18px;
          min-width: 200px;
        }

        .feedback-button-description-two{
          font-size: 18px;
          max-width: 250px;
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .feedback-page{
    margin-top: 60px;
    padding-bottom: 40px;

    .title-callback-page{
      font-size: 1.2em;
    }

    .feedback-main-content{

      .feedback-button{
        margin-top: 30px;

        .feedback-button-icon{
          width: 90px;
        }

        .feedback-button-description-one{
          font-size: 13px;
          min-width: 150px;
        }

        .feedback-button-description-two{
          font-size: 13px;
          max-width: 200px;
        }
      }
    }
  }
}


@media (max-width: 400px) {
  .feedback-page{

    .title-callback-page{
      font-size: 1.1em;
    }
  }
}