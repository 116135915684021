.home {
  width: 100%;

  .application-block-wrapper {
    width: 100%;
    height: 100%;

    .first-background {
      width: 100%;
      height: 32vw;
      position: relative;

      background: #E2E2E1;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

      .second-background {
        position: absolute;
        width: 85%;
        height: 75%;
        background: linear-gradient(to left, #353535 60%, #050505);
        z-index: 1;
      }

      .three-background {
        position: absolute;
        width: 60%;
        height: 35%;
        background: #C2C1C1;
        z-index: 2;

        margin-left: 15%;
        margin-top: 17%;
        box-shadow: 8px 12px 15px rgba(0, 0, 0, 0.8);


        .application-information-wrapper {
          position: absolute;
          z-index: 3;
          width: 100%;


          .application-information {
            font-size: 1.9rem;
            font-weight: 525;

            padding-left: 33%;
            padding-top: 3%;
          }

          .app-store {
            width: 23%;
            height: 10%;
            cursor: pointer;
            z-index: 6;

            margin-top: 3%;
            margin-left: 25%;
          }

          .google-play {
            width: 23%;
            height: 10%;
            cursor: pointer;
            z-index: 6;

            margin-left: 6%;
          }
        }
      }

      .description-second-background {
        position: absolute;
        z-index: 3;
        width: 100%;

        .company-name {
          font-size: 3.5rem;
          font-weight: 600;
          letter-spacing: 0.4rem;
          color: white;

          margin-left: 7%;
          margin-top: 6%;
        }

        .slogan {
          font-size: 1.9rem;
          font-weight: 500;
          color: white;

          margin-top: 2%;
          margin-left: 20%;
        }
      }

      .image-cup {
        position: absolute;
        max-width: 28%;
        z-index: 5;
        margin-left: 65%;
        margin-top: 4%;
      }
    }

    .links :nth-child(2){
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .links :nth-child(3){
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    .links {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 2rem;
      margin: 0 auto;
      padding: 90px 1rem 90px 1rem;
      max-width: 900px;

      .link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 240px;
        max-width: 370px;
        height: 90px;
        margin: 0 auto;

        border-radius: 25px;
        background: #999999;
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}


@media (max-width: 1650px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .three-background {

          .application-information-wrapper {

            .application-information {
              font-size: 1.6rem;
            }
          }
        }

        .description-second-background {

          .company-name {
            font-size: 3.3rem;

          }

          .slogan {
            font-size: 1.8rem;

          }
        }
      }
    }
  }
}


@media (max-width: 1450px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .three-background {

          .application-information-wrapper {

            .application-information {
              font-size: 1.5rem;
              margin-left: -2%;
            }
          }
        }

        .description-second-background {

          .company-name {
            font-size: 3rem;
            letter-spacing: 0.2rem;

          }

          .slogan {
            font-size: 1.6rem;
            margin-left: 18%;
          }
        }
      }
    }
  }
}


@media (max-width: 1250px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .three-background {

          .application-information-wrapper {

            .application-information {
              font-size: 1.4rem;
              margin-left: -3%;
            }
          }
        }

        .description-second-background {

          .company-name {
            font-size: 2.7rem;

          }

          .slogan {
            font-size: 1.5rem;
            margin-left: 16%;
          }
        }
        .image-cup{
          max-width: 27%;
          margin-top: 4%;
        }
      }
    }
  }
}


@media (max-width: 1100px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .three-background {

          .application-information-wrapper {

            .application-information {
              font-size: 1.3rem;
              margin-left: -4%;
            }
          }
        }

        .description-second-background {

          .company-name {
            font-size: 2.5rem;
            margin-top: 4%;
          }

          .slogan {
            font-size: 1.4rem;
            margin-left: 13%;
            margin-top: 2%;
          }
        }
      }
    }
  }
}


@media (max-width: 950px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .three-background {

          .application-information-wrapper {

            .application-information {
              font-size: 1.1rem;
              margin-left: -3%;
            }
          }
        }

        .description-second-background {

          .company-name {
            font-size: 2.2rem;
            margin-left: 5%;
            margin-top: 5%;
          }

          .slogan {
            font-size: 1.2rem;
            margin-left: 12%;
            margin-top: 1%;
          }
        }
        .image-cup{
          max-width: 26%;
          margin-top: 5%;
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .home {

    .application-block-wrapper {

      .first-background {
        height: 45vw;
        margin-top: 14px;

        .three-background {
          width: 76%;
          margin-left: 2%;
          margin-top: 23%;
          box-shadow: 6px 9px 15px rgba(0, 0, 0, 0.7);

          .application-information-wrapper {

            .application-information {
              font-size: 0;
            }

            .app-store {
              width: 38%;
              height: 10%;

              margin-top: 3%;
              margin-left: 2%;
            }

            .google-play {
              width: 38%;
              height: 10%;
              margin-left: 4%;
            }
          }
        }

        .description-second-background {

          .company-name {
            font-size: 1.8rem;
            letter-spacing: normal;

            margin-left: 19%;
            margin-top: 4%;
          }

          .slogan {
            max-width: 350px;
            font-size: 1.1rem;
            margin-left: 13%;
            margin-top: 3%;
            text-align: center;
          }
        }

        .image-cup {
          max-width: 35%;
          margin-top: 10%;
          margin-left: 64%;
        }
      }
    }
  }
}


@media (max-width: 750px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .description-second-background {

          .company-name {
            font-size: 1.7rem;
          }

          .slogan {
            font-size: 1rem;
            margin-left: 11%;
          }
        }
      }
    }
  }
}


@media (max-width: 660px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .description-second-background {

          .company-name {
            font-size: 1.6rem;
            font-weight: 500;

            margin-left: 16%;
            margin-top: 4%;
          }

          .slogan {
            margin-left: 6%;
          }
        }
      }

      .links{
        padding: 40px 1rem;
      }
    }
  }
}


@media (max-width: 550px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .description-second-background {

          .company-name {
            font-size: 1.4rem;

            margin-left: 10%;
            margin-top: 4%;
          }

          .slogan {
            max-width: 300px;
            font-size: 0.9rem;
            margin-left: 0%;
          }
        }
      }

      .links {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 1.9rem;
      }
    }
  }
}


@media (max-width: 450px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .description-second-background {

          .company-name {
            margin-left: 16%;
            margin-top: 8%;
            font-size: 1.1rem;
          }

          .slogan {
            font-size: 0;
          }
        }
      }

      .links{

        .link{
          font-size: 1.05rem;
          height: 70px;
        }
      }
    }
  }
}


@media (max-width: 350px) {
  .home {

    .application-block-wrapper {

      .first-background {

        .description-second-background {

          .company-name {
            margin-left: 13%;
            font-size: 1rem;
          }
        }
      }
    }
  }
}




