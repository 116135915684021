.switch {
  position: relative;
  display: block;
  width: 50px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondary);
    transition: .3s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 50%;
    transform: translate(0, 50%);
    background-color: white;
    transition: .3s;
  }

  input:checked + .slider:before {
    transform: translate(25px, 50%);
  }

  .slider {
    border-radius: 25px;
  }

  .slider:before {
    border-radius: 50%;
  }
}






