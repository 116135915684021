.locations {
  width: 100%;
  padding: 80px 0 60px 0;


  .title {
    font-size: 1.8rem;
    text-align: center;
    color: var(--text-color);
  }

  .addresses-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 20px auto;

    .addresses-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 580px;
      max-height: 522px;
      padding: 20px;
      margin: 20px 10px;


      border-radius: 30px;

      background: var(--primary);


      .addresses-block-title {
        margin-bottom: 15px;
        color: var(--text-color);
      }

      .addresses-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        overflow: auto;


        .addresses-list-item {
          width: 48%;
          min-width: 225px;
          margin-bottom: 3px;

          list-style-type: none;

          a {
            color: var(--text-color);
            text-decoration: none;
            font-size: 0.9rem;
          }
        }
      }
    }

    button {
      width: 208px;
      height: 60px;
      border: none;
      border-radius: 30px;
      padding: 15px;


      color: #ffffff;
      background: var(--primary-variant);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      transition: .2s;

      font-size: 21px;
      font-weight: 500;

      &:hover {
        background: var(--primary);
      }
    }

    .button-wrapper {
      margin: auto auto;

    }
  }

  .map{
    margin: 0 20px;

    .iframe-wrapper {
      margin: 0 auto;
      max-width: 1220px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      position: relative;
      overflow: hidden;

      border: 3px solid var(--primary-variant);

      iframe {
        max-width: 100%;
        margin-top: -60px;
        margin-bottom: -15px;
        border: none;
      }
    }
  }
}


@media (max-width: 1200px) {
  .locations {
    .addresses-group {
      justify-content: center;
      max-width: 700px;

      .button-wrapper {
        margin: 20px 0;

        button {
          position: static;
        }
      }
    }

    .map {
      margin: 0 10px 30px 10px;
    }
  }
}


@media (max-width: 800px) {
  .locations {
    padding: 40px 0 30px;
  }
}


@media (max-width: 500px) {
  .locations {
    padding: 0 0 30px 0;

    .title {
      font-size: 1.4rem;
      margin-top: 45px;
    }

    .addresses-group {
      .addresses-block {
        max-height: none;

        .addresses-block-title {
          font-size: 1.1rem;
        }
      }
    }
  }
}

