.slider-banner {
  position: relative;
  width: 100%;
  height: 40vw;
  margin: auto;
  box-sizing: border-box;
  overflow: hidden;


  a {
    position: absolute;
    width: 355px;
    height: 95px;
    bottom: 100px;
    left: 7%;
    z-index: 3;

    button {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 20px;
      padding: 15px;



      color: #ffffff;
      background: var(--primary-variant);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      transition: .2s;

      font-size: 25px;
      font-weight: 600;

      &:hover {
        background: var(--primary);
      }
    }
  }


  .slick-slide {
    height: 40vw;
  }

  .slick-slide > div {
    height: 100%;

  }

  .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {
    position: relative;
    width: 100%;
    height: 40vw;
    background-size: cover;
    background-repeat: no-repeat;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(50, 50, 50, 0.35);
      z-index: 2;
    }

    .title {
      font-weight: 600;
      font-size: 3rem;
      max-width: 580px;
      color: white;
      position: absolute;
      z-index: 10;
      margin-top: 10.5%;
      margin-left: 7%;
    }
  }

  .slider-item-1 {
    background-image: url('../../../assets/images/banner1.jpg');
  }

  .slider-item-2 {
    background-image: url('../../../assets/images/banner2.jpg');
  }

  .slider-item-3 {
    background-image: url('../../../assets/images/banner3.jpg');
  }

  .slider-item-4 {
    background-image: url('../../../assets/images/banner4.jpg');
  }

  .slider-item-5 {
    background-image: url('../../../assets/images/banner5.jpg');
  }

  .slider-item-6 {
    background-image: url('../../../assets/images/banner6.jpg');
  }

  .slider-item-7 {
    background-image: url('../../../assets/images/banner7.jpg');
  }
}


@media (max-width: 1600px) {
  .slider-banner {

    a {
      width: 320px;
      height: 85px;
      bottom: 70px;

      .button {
        font-size: 22px;
      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 2.7rem;
        max-width: 520px;
      }
    }
  }

}


@media (max-width: 1400px) {
  .slider-banner {

    a {
      width: 280px;
      height: 75px;
      bottom: 70px;

      .button {
        font-size: 20px;
      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 2.4rem;
        max-width: 470px;
      }
    }
  }

}


@media (max-width: 1150px) {
  .slider-banner {


    a {
      width: 250px;
      height: 65px;
      bottom: 60px;

      .button {
        font-size: 18px;
        padding: 5px;
      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 2.1rem;
        max-width: 410px;
      }
    }
  }
}


@media (max-width: 950px) {
  .slider-banner {

    a {
      width: 220px;
      height: 60px;
      bottom: 50px;

      .button {
        font-size: 16px;
      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 1.8rem;
        max-width: 350px;
      }
    }
  }
}


@media (max-width: 800px) {
  .slider-banner {
    margin-top: 14px;

    a {
      width: 180px;
      height: 50px;
      bottom: 40px;

      .button {
        font-size: 13px;

      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 1.5rem;
        max-width: 290px;
      }
    }
  }
}


@media (max-width: 650px) {
  .slider-banner {

    a {
      width: 140px;
      height: 40px;
      bottom: 30px;

      .button {
        font-size: 10px;
        font-weight: 500;
      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 1.2rem;
        max-width: 230px;
      }
    }
  }
}


@media (max-width: 500px) {
  .slider-banner {

    a {
      width: 110px;
      height: 30px;
      bottom: 20px;

      .button {
        font-size: 8px;

      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 0.9rem;
        max-width: 170px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slider-banner {

    a {
      width: 95px;
      height: 25px;

      .button {
        font-size: 6px;
      }
    }

    .slider-item-1, .slider-item-2, .slider-item-3, .slider-item-4, .slider-item-5, .slider-item-6, .slider-item-7 {

      .title {
        font-size: 0.8rem;
        max-width: 160px;
      }
    }
  }
}