.sequence-of-cooperation-page {
  width: 100%;
  margin-top: 100px;
  position: relative;

  h1 {
    color: var(--text-color);
    text-align: center;

  }


  img {
    width: 550px;
    display: block;
    margin: 50px auto 0 auto;
  }

  .first-inscription {
    font-size: 23px;
    font-weight: 600;
    color: var(--text-color);
    position: absolute;
    left: 57%;
    bottom: 80%;
  }

  .second-inscription {
    font-size: 23px;
    font-weight: 600;
    color: var(--text-color);
    position: absolute;
    left: 66%;
    bottom: 58%;
  }

  .third-inscription {
    font-size: 23px;
    font-weight: 600;
    color: var(--text-color);
    position: absolute;
    left: 66%;
    bottom: 28%;
  }

  .fourth-inscription {
    font-size: 23px;
    font-weight: 600;
    color: var(--text-color);
    position: absolute;
    left: 57%;
    bottom: 1%;
  }

  .fifth-inscription {
    font-size: 23px;
    font-weight: 600;
    max-width: 500px;
    color: var(--text-color);
    position: absolute;
    left: 14%;
    bottom: 0;
  }

  .sixth-inscription {
    font-size: 23px;
    font-weight: 600;
    color: var(--text-color);
    position: absolute;
    left: 14%;
    bottom: 25%;
  }
}


@media (max-width: 1700px) {
  .sequence-of-cooperation-page {

    .second-inscription {
      left: 67%;
    }

    .third-inscription {
      left: 68%;
    }

    .fourth-inscription {
      left: 58%;
    }

    .fifth-inscription {
      left: 11%;
    }

    .sixth-inscription {
      left: 11%;
    }
  }
}


@media (max-width: 1500px) {
  .sequence-of-cooperation-page {

    .first-inscription {
      left: 58%;
    }

    .second-inscription {
      left: 70%;
    }

    .third-inscription {
      left: 71%;
    }

    .fourth-inscription {
      left: 60%;
    }

    .fifth-inscription {
      left: 6%;
    }

    .sixth-inscription {
      left: 5%;
    }
  }
}


@media (max-width: 1300px) {
  .sequence-of-cooperation-page {

    img {
      width: 450px;
    }

    .first-inscription {
      font-size: 20px;
      left: 59%;
      bottom: 77%;
    }

    .second-inscription {
      font-size: 20px;
      left: 69%;
    }

    .third-inscription {
      font-size: 20px;
      left: 70%;
    }

    .fourth-inscription {
      font-size: 20px;
      left: 60%;
      bottom: 1%;
    }

    .fifth-inscription {
      font-size: 20px;
      left: 5%;
    }

    .sixth-inscription {
      font-size: 20px;
      left: 5%;
    }
  }
}


@media (max-width: 1200px) {
  .sequence-of-cooperation-page {

    h1 {
      font-size: 1.7em;
    }
  }
}


@media (max-width: 1100px) {
  .sequence-of-cooperation-page {

    img {
      width: 350px;
      margin-top: 70px;
    }

    .first-inscription {
      font-size: 18px;
      left: 60%;
      bottom: 69%;
    }

    .second-inscription {
      font-size: 18px;
      left: 69%;
      bottom: 46%;
    }

    .third-inscription {
      font-size: 18px;
      left: 69%;
      bottom: 23%;
    }

    .fourth-inscription {
      font-size: 18px;
      left: 62%;
      bottom: 0;
    }

    .fifth-inscription {
      font-size: 18px;
      left: 10%;
      max-width: 340px;
    }

    .sixth-inscription {
      font-size: 18px;
      left: 3%;
    }
  }
}


@media (max-width: 900px) {
  .sequence-of-cooperation-page {

    img {
      width: 300px;
      margin-top: 50px;
    }

    .first-inscription {
      font-size: 16px;
      left: 61%;
      bottom: 71%;
    }

    .second-inscription {
      font-size: 16px;
      left: 71%;
      bottom: 46%;
    }

    .third-inscription {
      font-size: 16px;
      left: 72%;
      bottom: 23%;
    }

    .fourth-inscription {
      font-size: 16px;
      left: 63%;
      bottom: 0;
    }

    .fifth-inscription {
      font-size: 16px;
      left: 7%;
      max-width: 300px;
    }

    .sixth-inscription {
      font-size: 16px;
      left: 8%;
      bottom: 20%;
      max-width: 200px;
    }
  }
}


@media (max-width: 800px) {
  .sequence-of-cooperation-page {

    h1 {
      font-size: 1.4em;
    }
  }
}


@media (max-width: 700px) {
  .sequence-of-cooperation-page {

    img {
      width: 200px;
      margin-top: 30px;
    }

    .first-inscription {
      font-size: 13px;
      left: 60%;
      bottom: 71%;
    }

    .second-inscription {
      font-size: 13px;
      left: 70%;
      bottom: 46%;
    }

    .third-inscription {
      font-size: 13px;
      left: 71%;
      bottom: 23%;
    }

    .fourth-inscription {
      font-size: 13px;
      left: 64%;
      bottom: 0;
    }

    .fifth-inscription {
      font-size: 13px;
      left: 2%;
      max-width: 200px;
    }

    .sixth-inscription {
      font-size: 13px;
      left: 6%;
      bottom: 20%;
      max-width: 150px;
    }
  }
}


@media (max-width: 500px) {
  .sequence-of-cooperation-page {
    margin-top: 60px;

    h1 {
      font-size: 1.2em;
    }

    img {
      width: 150px;
    }

    .first-inscription {
      font-size: 11px;
      left: 60%;
      bottom: 66%;
    }

    .second-inscription {
      font-size: 11px;
      left: 69%;
      bottom: 46%;
    }

    .third-inscription {
      font-size: 11px;
      left: 70%;
      bottom: 20%;
    }

    .fourth-inscription {
      font-size: 11px;
      left: 63%;
      bottom: 0;
    }

    .fifth-inscription {
      font-size: 11px;
      left: 0.5%;
      max-width: 200px;
    }

    .sixth-inscription {
      font-size: 11px;
      left: 6%;
      bottom: 20%;
      max-width: 150px;
    }
  }
}


@media (max-width: 400px) {
  .sequence-of-cooperation-page {

    h1 {
      font-size: 1.1em;
    }

    img {
      width: 120px;
    }

    .first-inscription {
      font-size: 8px;
      left: 58%;
      bottom: 63%;
    }

    .second-inscription {
      font-size: 8px;
      left: 66%;
      bottom: 44%;
    }

    .third-inscription {
      font-size: 8px;
      left: 67%;
      bottom: 23%;
    }

    .fourth-inscription {
      font-size: 8px;
      left: 63%;
      bottom: 0;
    }

    .fifth-inscription {
      font-size: 8px;
      left: 6%;
      max-width: 140px;
    }

    .sixth-inscription {
      font-size: 8px;
      left: 11%;
      bottom: 18%;
      max-width: 100px;
    }
  }
}


@media (max-width: 350px) {
  .sequence-of-cooperation-page {

    img {
      width: 100px;
    }

    .first-inscription {
      font-size: 7px;
      left: 58%;
      bottom: 60%;
    }

    .second-inscription {
      font-size: 7px;
      left: 67%;
    }

    .third-inscription {
      font-size: 7px;
      left: 68%;
    }

    .fourth-inscription {
      font-size: 7px;
    }

    .fifth-inscription {
      font-size: 7px;
      left: 4%;
      max-width: 120px;
    }

    .sixth-inscription {
      font-size: 7px;
      left: 9%;
    }
  }
}
