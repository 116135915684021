li {
  list-style-type: none;
}

.slide3-container {
  width: 100%;
  margin: 0 0 80px 260px;


  .slide3-wrapper {
    width: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;


    .phone-slide3 {
      width: 24%;


      .phone-screen-advantage {
        width: 100%;

      }
    }


    .description-slide3 {
      width: 35%;
      margin-left: 10%;

      color: var(--text-color);

      .title-slide3 h3{
        margin-bottom: 20px;
        font-size: 1.3rem;
      }

      .main-description li{
        font-size: 19px;
        font-weight: 500;
      }
    }
  }
}


@media (max-width: 1350px) {
  .slide3-container {
    margin: 0 0 80px 110px;

    .slide3-wrapper {

      .phone-slide3 {
        width: 35%;
      }
    }

    .description-slide3 {

      .title-slide3 h3{
        font-size: 1.2rem;
      }

      .main-description li{
        font-size: 18px;
        font-weight: 450;
      }
    }
  }
}


@media (max-width: 1050px) {
  .slide3-container {
    margin: 0;

    .slide3-wrapper {
      display: flex;
      flex-direction: column;
      min-height: auto;


      .phone-slide3 {
        width: 40%;
        margin-bottom: 20px;


        .phone-screen-advantage {
        }
      }

      .description-slide3 {
        margin: 40px 0 30px 0;
        width: 50%;
      }
    }
  }
}


@media (max-width: 800px) {
  .slide3-container {

    .slide3-wrapper {

      .phone-slide3 {
        width: 55%;

        .phone-screen-advantage {
        }
      }

      .description-slide3 {
        width: 80%;

        .title-slide3 h3{
          font-size: 1.08rem;
        }

        .main-description li{
          font-size: 16px;
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .slide3-container {

    .slide3-wrapper {

      .phone-slide3 {
        width: 80%;
        margin-bottom: 0;

        .phone-screen-advantage {
        }

      }

      .description-slide3 {
        width: 100%;
        max-width: 330px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slide3-container {

    .slide3-wrapper {

      .phone-slide3 {
        width: 90%;

        .phone-screen-advantage {
        }
      }

      .description-slide3 {
        max-width: 200px;
      }
    }
  }
}