@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #cecece;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  display: flex;
}

div[theme="light"] {
  --background: #E9E9E9;
  --text-color: #000000;
  --primary: #CDCDCD;
  --primary-variant: #999999;
  --secondary: #FF1332;
}

div[theme="dark"] {
  --background: #1a1a1a;
  --text-color: #ffffff;
  --primary: #5a5a5a;
  --primary-variant: #474747;
  --secondary: #FF1332;
}

select,
input[type="text"],
input[type="date"],
input[type="tel"],
input[type="email"],
textarea {
  min-height: 50px;
  border: 1px solid var(--text-color);
  border-radius: 30px;
  outline: none;
  padding: 13px;
  background: transparent;
  color: var(--text-color);

  &::placeholder {
    color: #999999;
  }
}

textarea {
  padding: 20px 15px;

  resize: none;

  &::-webkit-scrollbar {
    display: none;
  }
}





