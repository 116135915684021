.model-car-page{
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px 0 150px;

  .description-model{
    font-size: 1.3rem;
    font-weight: 550;
    width: 30%;
    color: var(--text-color);
  }

  .model-car-3d{
    width: 70%;
    height: 100%;
    margin-left: 4%;

    &>div{   // Щоб фотографія не відображалася поверх хедера
      z-index: 99;
    }

    canvas + div{
      width: 100%;
      height: 100%;
    }

    .img-car{
      width: 100%;
      height: 100%;

      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}


@media (max-width: 1700px) {
  .model-car-page{
    height: 750px;
  }
}


@media (max-width: 1600px) {
  .model-car-page{
    height: 700px;

    .description-model{
      font-size: 1.2rem;
    }
  }
}


@media (max-width: 1500px) {
  .model-car-page{
    height: 645px;
  }
}


@media (max-width: 1400px) {
  .model-car-page{
    height: 600px;

    .description-model{
      font-size: 1.1rem;
    }
  }
}


@media (max-width: 1300px) {
  .model-car-page{
    height: 550px;
  }
}


@media (max-width: 1200px) {
  .model-car-page{
    height: 495px;

    .description-model{
      font-size: 1rem;
    }
  }
}


@media (max-width: 1100px) {
  .model-car-page{
    height: 465px;
  }
}


@media (max-width: 1000px) {
  .model-car-page{
    height: 600px;
    flex-direction: column-reverse;
    padding: 0;

    .description-model{
      width: 60%;
      height: 14%;
    }

    .model-car-3d{
      width: 100%;
      height: 86%;
      margin-left: 0;

      .img-car{

        img{
         margin-left: 5%;
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .model-car-page{

    .model-car-3d{

      .img-car{

        img{
          margin-left: 0;
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .model-car-page{
    height: 500px;

    .description-model{
      width: 90%;
    }
  }
}


@media (max-width: 500px) {
  .model-car-page{
    height: 400px;

    .description-model{
      width: 80%;
      font-size: 0.8rem;
    }
  }
}


@media (max-width: 400px) {
  .model-car-page{
    height: 300px;
  }
}
