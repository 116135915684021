.terms-cooperation-page{
  width: 100%;
  margin-top: 50px;

  h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 50px;
    color: var(--text-color);
  }

  .list-terms-cooperation{
    margin-left: 15%;
    color: var(--text-color);


    .item-terms-cooperation{
      font-weight: 600;
      font-size: 1.3rem;
      margin-bottom: 20px;

    }
  }
}


@media (max-width: 1200px) {
  .terms-cooperation-page{

    h1{
      font-size: 1.7em;
    }

    .list-terms-cooperation{

      .item-terms-cooperation{
        font-size: 1.1rem;
      }
    }
  }
}


@media (max-width: 1000px) {
  .terms-cooperation-page {
    margin-top: 100px;

    h1 {
      margin-bottom: 50px;
    }
  }
}


@media (max-width: 800px) {
  .terms-cooperation-page{

    h1{
      font-size: 1.4em;
    }

    .list-terms-cooperation{
      margin-left: 0;
      padding: 0 80px;

      .item-terms-cooperation{
        font-size: 1rem;
      }
    }
  }
}


@media (max-width: 700px) {
  .terms-cooperation-page{

    .list-terms-cooperation{
      padding: 0 40px;
    }
  }
}


@media (max-width: 500px) {
  .terms-cooperation-page{
    margin-top: 30px;

    h1{
      font-size: 1.2em;
      margin-top: 60px;
    }

    .list-terms-cooperation{

      .item-terms-cooperation{
        font-size: 0.9rem;
      }
    }
  }
}


@media (max-width: 400px) {
  .terms-cooperation-page{

    h1{
      font-size: 1.1em;
      margin-top: 0;
    }

    .list-terms-cooperation{

      .item-terms-cooperation{
        font-size: 0.8rem;
      }
    }
  }
}
