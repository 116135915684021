.sr {

  .slick-slider {
    display: flex !important;
    justify-content: center;
    padding: 0 80px;
    align-items: center;

  }

    .slick-next, .slick-prev {
      z-index: 50;
      height: 250px;
      width: 70px;
      top: 50%;
      transform: translate(0, -50%);
      background: rgba(153, 153, 153, 0.30);




      &:before {
        width: 40px;
        height: 40px;
        background-size: cover !important;
        background-position: center !important;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }


  .slick-prev {
    left: 0;
    border-radius: 0 30px 30px 0;
    &:before {
      content: " ";
      background: url("../../assets/icons/row-left.svg");
    }
  }

  .slick-next {
    border-radius: 30px 0 0 30px;
    right: 0;
    &:before {
      content: " ";
      background: url("../../assets/icons/row-right.svg");
    }
  }

  .slide-wrapper {
    width: 100% !important;
    display: flex !important;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .sr {
    .slick-slider {
      padding: 0 60px;
    }

    .slick-next, .slick-prev {
      height: 120px;
      width: 40px;
      position: fixed;

      &:before {
        width: 20px;
        height: 20px;
      }
    }

    .slick-prev {

      &:before {

      }
    }

    .slick-next {

      &:before {

      }
    }

    .slide-wrapper {

    }
  }
}