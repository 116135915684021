.job-application {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 50px;





  .job-application-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 750px;
    padding: 1rem;
    border-radius: 30px;


    background: var(--primary);

    .title {
      font-weight: 600;
      font-size: 1.8rem;
      color: var(--text-color);
    }

    .add-photo-block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      margin-left: 30px;

      .add-photo-button {
        display: block;
        width: 125px;
        height: 125px;
        margin-top: 15px;

        overflow: hidden;
        border-radius: 50%;

        background: var(--primary-variant);
        background-image: url("../../../assets/icons/PhotoIcon.svg");
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        input {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);

          object-fit: cover;
          object-position: center;
        }
      }

      .description {
        width: 230px;
        height: 93px;
        color: var(--text-color);
        font-size: 1.0rem;
        margin-left: 20px;
        margin-top: 15px;
        padding: 5px;
      }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;


        .date-of-birth {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-content: center;

          > label {
            width: 155px;
            display: block;
            margin: 10px 0;

            font-weight: 600;
            color: var(--text-color);
          }


          .date {
            width: 110px;
            margin-left: 62px;
          }

          .month {
            width: 215px;
            margin-right: 5px;
            margin-left: 5px;
          }

          .year {
            width: 165px;
          }

        }

        .date-of-birth.error {

          > label {
            color: var(--secondary);
          }

          .date.error input {
            border-color: var(--secondary);
          }

          .month.error input {
            border-color: var(--secondary);
          }

          .year.error input {
            border-color: var(--secondary);
          }
        }

        > label {
          display: block;
          margin: 10px 0;

          font-weight: 600;
          color: var(--text-color);
        }

        > input, .checkbox-group, .select {
          width: 100%;
          max-width: 500px;
        }

        .select {
          input {
            width: 100%;
          }
        }


        .checkbox-group {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;

          .checkbox {
            margin: 0 5%;
            display: flex;
            align-items: center;

            input {
              position: relative;
              appearance: none;

              border-radius: 50%;
              width: 20px;
              height: 20px;
              margin-left: 7px;

              background: var(--primary-variant);
              outline: none;
              cursor: pointer;

              &:checked {
                border: 2px solid var(--secondary);
                background: transparent;

                &:before {
                  content: " ";

                  position: absolute;
                  left: 50%;
                  top: 50%;

                  width: 60%;
                  height: 60%;
                  transform: translate(-50%, -50%);

                  background: var(--secondary);
                  border-radius: 50%;
                }
              }
            }

            label {
              color: var(--text-color);
            }
          }
        }

        &:nth-child(11) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          label {
            margin-bottom: 10px;
          }

          textarea {
            width: 100%;
          }
        }
      }

      .form-item.error input {
        border-color: var(--secondary);
      }

      .form-item.error label {
        color: var(--secondary);
      }
    }
    button {
      margin-top: 15px;
      margin-bottom: 10px;
      min-width: 150px;
      border: none;
      border-radius: 30px;
      padding: 15px;

      color: #ffffff;
      background: var(--primary-variant);
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
      cursor: pointer;
      transition: .2s;

      &:hover {
        background: var(--primary);
      }
    }
  }
}

@media (max-width: 800px) {
  .job-application {
    padding: 60px 0 50px 0;

    .job-application-bg {


      form {

        .form-item {
          display: flex;
          flex-direction: column;

          .date-of-birth {
            max-width: 500px;

            > label {
              width: 100%;
              text-align: center;
            }

            .date {
              margin-left: 0;
            }
          }

          &:nth-child(11) {

            label {
              margin-left: 27%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 590px) {
  .job-application {
    padding: 50px 0;


    .job-application-bg {

      .add-photo-block {
        margin-left: 0;
      }

      form {

        .form-item {
          display: flex;
          flex-direction: row;

          .form-item :nth-child(3) > label {
            width: 100%;
          }

          &:nth-child(11) {

            label {
              margin-left: 0;
            }
          }

          .date-of-birth {

            > label {
              text-align: start;
            }

            .date {
              width: 60%;
            }

            .month {
              width: 60%;
              margin: 10px 0;
            }

            .year {
              width: 60%;
            }
          }
        }
      }
    }
  }
}