.all-page{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 50px;

  .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 750px;
    padding: 1rem;
    border-radius: 30px;

    background: var(--primary);

    .title-form{
      font-weight: 600;
      font-size: 1.8rem;
      color: var(--text-color);
      margin: 30px 0 40px 0;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-cell {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .number-of-birth {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-content: center;

          label {
            width: 155px;
            display: block;
            margin: 10px 0;

            font-weight: 600;
            color: var(--text-color);
          }

          .day {
            width: 110px;
            margin-left: 62px;
          }

          .month {
            width: 215px;
            margin-right: 5px;
            margin-left: 5px;
          }

          .year {
            width: 165px;
          }
        }
        .number-of-birth.error {

           label {
            color: var(--secondary);
          }

          .day.error input {
            border-color: var(--secondary);
          }

          .month.error input {
            border-color: var(--secondary);
          }

          .year.error input {
            border-color: var(--secondary);
          }
        }

        label {
          display: block;
          max-width: 200px;
          margin: 10px 0;

          font-weight: 600;
          color: var(--text-color);
        }

        .smaller-margin{
          margin: -8px 0;
        }

        input, .checkbox-group, .select {
          width: 100%;
          max-width: 500px;
        }

        .select {
          input {
            width: 100%;
          }
        }

        .checkbox-form-group {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          width: 500px;

          .checkbox-item {
            margin: 0 5%;
            display: flex;
            align-items: center;

            input {
              position: relative;
              appearance: none;

              border-radius: 50%;
              width: 20px;
              height: 20px;
              margin-left: 7px;

              background: var(--primary-variant);
              outline: none;
              cursor: pointer;

              &:checked {
                border: 2px solid var(--secondary);
                background: transparent;

                &:before {
                  content: " ";

                  position: absolute;
                  left: 50%;
                  top: 50%;

                  width: 60%;
                  height: 60%;
                  transform: translate(-50%, -50%);

                  background: var(--secondary);
                  border-radius: 50%;
                }
              }
            }

            label {
              color: var(--text-color);
            }
          }
        }
      }
      .form-cell.error input {
        border-color: var(--secondary);
      }

      .form-cell.error label {
        color: var(--secondary);
      }
    }

    .button {
      margin-top: 10px;
      margin-bottom: 10px;
      min-width: 150px;
      border: none;
      border-radius: 30px;
      padding: 15px;

      color: #ffffff;
      background: var(--primary-variant);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      transition: .2s;

      &:hover {
        background: var(--primary);
      }
    }
  }
}


@media (max-width: 800px) {
  .all-page{

    .form{

      form{

        .form-cell{
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          .number-of-birth{
            width: 500px;
            max-width: 500px;

            label{
              min-width: 100%;
              text-align: center;
            }

            .day{
              margin-left: 0;
            }
          }
          label{
            max-width: 500px;
          }

          .smaller-margin{
            margin: 10px 0;
          }
        }
      }
    }
  }
}


@media (max-width: 590px) {
  .all-page{
    padding: 50px 0;

    .form{

      form{

        .form-cell{
          display: flex;
          flex-direction: row;

          .number-of-birth{

            label{
              text-align: start;
            }

            .day{
              width: 60%;
            }

            .month{
              width: 60%;
              margin: 10px 0;
            }

            .year{
              width: 60%;
            }
          }
        }
      }
    }
  }
}