li {
  list-style-type: none;
}

.slide9-container {
  width: 100%;
  margin: 0 0 80px 260px;

  .slide9-wrapper {
    width: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;

    .phone-slide9 {
      width: 24%;

      .phone-screen-design {
        width: 100%;

      }
    }

    .slide9-description {
      width: 40%;
      margin-left: 10%;
      color: var(--text-color);

      h3 {
        margin-bottom: 20px;
        font-size: 1.3rem;
      }

      p {
        font-size: 19px;
        font-weight: 500;
      }

      li {
        font-size: 19px;
        font-weight: 500;
      }
    }
  }
}


@media (max-width: 1350px) {
  .slide9-container {
    margin: 0 0 80px 110px;

    .slide9-wrapper {

      .phone-slide9 {
        width: 35%;
      }

      .slide9-description {

        h3 {
          font-size: 1.2rem;
        }

        p {
          font-size: 18px;
          font-weight: 450;
        }

        li {
          font-size: 18px;
          font-weight: 450;
        }
      }
    }
  }
}


@media (max-width: 1050px) {
  .slide9-container {
    margin: 0;

    .slide9-wrapper {
      display: flex;
      flex-direction: column;
      min-height: auto;


      .phone-slide9 {
        width: 40%;
        margin-bottom: 20px;
      }

      .slide9-description {
        margin: 40px 0 30px 0;
        width: 50%;
      }
    }
  }
}


@media (max-width: 800px) {
  .slide9-container {

    .slide9-wrapper {

      .phone-slide9 {
        width: 55%;
      }

      .slide9-description {
        width: 80%;

        h3 {
          font-size: 1.08rem;
        }

        p {
          font-size: 16px;
        }

        li {
          font-size: 16px;
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .slide9-container {

    .slide9-wrapper {

      .phone-slide9 {
        width: 80%;
        margin-bottom: 0;
      }

      .slide9-description {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slide9-container {

    .slide9-wrapper {

      .phone-slide9 {
        width: 90%;
      }

      .slide9-description {
        max-width: 200px;

      }
    }
  }
}
