.popup-container {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .main-content {
    width: 400px;
    height: 180px;

    background-color: var(--primary);
    border-radius: 35px;
    padding: 20px;

    .main-text{
      font-weight: 550;
      font-size: 1.4rem;
      text-align: center;
      color: var(--text-color);
      margin-top: 20px;
    }

    img {
      margin-left: 95%;
      cursor: pointer;
    }

    .button-wrapper {

      button {
        width: 130px;
        height: 50px;
        border: none;
        border-radius: 30px;
        margin-top: 40px;
        margin-left: 115px;
        display: flex;
        justify-content: center;
        align-items: center;


        color: #ffffff;
        background: var(--primary-variant);
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        cursor: pointer;
        transition: .2s;

        font-size: 21px;
        font-weight: 500;

        &:hover {
          background: var(--primary);
        }
      }

      a{
        text-decoration: none;
      }
    }
  }
}


@media (max-width: 500px) {
  .popup-container {

    .main-content {
      width: 300px;
      height: 135px;

      .main-text{
        font-size: 1rem;
        margin-top: 10px;
      }

      img{
        width: 10px;
      }

      .button-wrapper {

        button {
          width: 100px;
          height: 35px;
          margin-top: 20px;
          margin-left: 80px;
          font-size: 15px;
        }
      }
    }
  }
}