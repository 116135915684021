.model-maf-page {
  width: 100%;
  height: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px 0 150px;

  .description-model {
    font-size: 1.3rem;
    font-weight: 550;
    width: 36%;
    color: var(--text-color);
  }

  .model-maf-3d {
    width: 64%;
    height: 100%;
    margin-left: 4%;

    &>div{   // Щоб фотографія не відображалася поверх хедера
      z-index: 99;
    }

    canvas + div{
      width: 100%;
      height: 100%;
    }

    .img-maf{
      width: 100%;
      height: 100%;

      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}


@media (max-width: 1600px) {
  .model-maf-page{
    height: 760px;

    .description-model{
      font-size: 1.2rem;
    }
  }
}


@media (max-width: 1400px) {
  .model-maf-page{
    height: 650px;

    .description-model{
      font-size: 1.1rem;
    }
  }
}


@media (max-width: 1200px) {
  .model-maf-page{
    height: 550px;
    padding-left: 100px;

    .description-model{
      font-size: 1rem;
    }
  }
}


@media (max-width: 1000px) {
  .model-maf-page{
    flex-direction: column-reverse;
    padding: 0 50px;

    .description-model{
      width: 70%;
      height: 14%;
    }
    .model-maf-3d {
      width: 100%;
      height: 86%;
      margin-left: 0;

      .img-maf{

        img{
          margin-left: 20%;
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .model-maf-page{

    .model-maf-3d{

      .img-maf{

        img{
          margin-left: 20%;
        }
      }
    }
  }
}


@media (max-width: 700px) {
  .model-maf-page{

    .model-maf-3d{

      .img-maf{

        img{
          margin-left: 10%;
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .model-maf-page{
    height: 450px;

    .description-model{
      width: 90%;
      font-size: 1rem;
    }
  }
}


@media (max-width: 500px) {
  .model-maf-page{
    height: 330px;
    padding: 0;

    .description-model{
      width: 90%;
      font-size: 0.8rem;
    }

    .model-maf-3d{

      .img-maf{

        img{
          margin-left: 20%;
        }
      }
    }
  }
}


@media (max-width: 400px) {
  .model-maf-page{

    .model-maf-3d{

      .img-maf{

        img{
          margin-left: 10%;
        }
      }
    }
  }
}