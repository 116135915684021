.presentation-page{
  width: 100%;
  padding: 80px 0 60px;

  .franchise-presentation-section{
    width: 90%;
    height: 780px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 30px;

    .presentation-wrapper{
      width: 100%;
      height: 100%;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
}

@media (max-width: 576px) {
  .presentation-page{
    padding-top: 50px;
    padding-bottom: 35px;
  }
}