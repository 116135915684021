.franchise-advantage-page{
  width: 100%;
  padding-top: 100px;

  h1{
    color: var(--text-color);
    text-align: center;
    margin: 0;
  }

  .advantage-main-content{

    .advantage-section{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding-top: 50px;

      .advantage-item{
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          background-color: white;
          border-radius: 50%;
        }

        p{
          font-weight: 600;
          font-size: 1.1rem;
          width: 300px;
          margin-left: 30px;
          color: var(--text-color);
        }
      }
    }
  }
}


@media (max-width: 1200px) {
  .franchise-advantage-page{

    h1{
      font-size: 1.7em;
    }
  }
}


@media (max-width: 1000px) {
  .franchise-advantage-page{
    padding-top: 100px;

    h1{
    }

    .advantage-main-content{
      padding: 0 0 0 30px;

      .advantage-section{

        .advantage-item{

          img{
            width: 100px;
          }

          p{
             font-size: 1rem;
            max-width: 230px;
          }
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .franchise-advantage-page{

    h1{
      font-size: 1.4em;
    }

    .advantage-main-content{

      .advantage-section{

        .advantage-item{

          p{
            font-size: 0.9rem;
            max-width: 180px;
          }
        }
      }
    }
  }
}


@media (max-width: 700px) {
  .franchise-advantage-page{

    .advantage-main-content{
      padding-left: 15px;

      .advantage-section{
        margin-bottom: 0;

        .advantage-item{
          flex-direction: column;

          img{
            margin-bottom: 15px;
          }

          p{
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .franchise-advantage-page{
    padding-top: 60px;

    h1{
      font-size: 1.2em;
      margin-bottom: 10px;
    }

    .advantage-main-content{
      padding-left: 0;

      .advantage-section{
        padding-top: 40px;

        .advantage-item{

          img{
            width: 90px;
          }

          p{
            font-size: 0.7rem;
            max-width: 120px;
          }
        }
      }
    }
  }
}


@media (max-width: 400px) {
  .franchise-advantage-page {

    h1 {
      font-size: 1.1em;
    }
  }
}