.feedback {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
  margin-left: 8%;
  margin-bottom: 90px;


  .left-block {
    width: 100%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-right: 8%;

    .rating-block {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 1.6rem;
        color: var(--text-color);
      }

      .stars-list {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .stars-list-item {
          width: 35px;
          margin: 0 8px;

          list-style-type: none;
          cursor: pointer;

          svg {
            width: 100%;

            path {
              transition: .2s;
              fill: #CDCDCD;
            }
          }

          &.active {
            svg {
              path {
                fill: #FFD54D;
              }
            }
          }
        }
      }

      .rating {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 90px;

        svg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          transition: .2s;

          opacity: 0;

          &.active {
            opacity: 1;
          }
        }
      }
    }

    .form-block {
      width: 100%;
      margin-right: 80px;

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 450px;

        margin-left: 60px;

        .form-item {
          width: 100%;

          .select, textarea {
            margin-bottom: 10px;
          }

          .select, input, textarea {
            width: 100%;
          }
        }

        .form-item.error input {
          border-color: var(--secondary);
        }

        .form-item.error textarea {
          border-color: var(--secondary);
        }
      }
    }

    button {
      margin-top: 30px;
      min-width: 150px;
      border: none;
      border-radius: 30px;
      padding: 15px;

      color: #ffffff;
      background: var(--primary-variant);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      transition: .2s;

      &:hover {
        background: var(--primary);
      }
    }
  }

  .right-block {
    display: flex;

    margin-right: 6%;

    .message {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      transform: translate(30px, -18px);

      background: url("../../../assets/images/dialog.png") no-repeat;
      background-position: center bottom;
      background-size: contain;
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--text-color);
    }

    .racoon {
      width: 275px;
      transform: translate(-40px, 200px);
    }
  }

  .text-message {
    text-align: center;
    position: absolute;
    left: 30px;
    right: 25px;
    top: 180px;
    bottom: 50px;
  }
}


@media (max-width: 1100px) {
  .feedback {

    .left-block {
      margin-right: 0;
    }

    .right-block {
      margin-right: 0;

      .message {
        width: 180px;
        font-size: 1rem;

      }

      .racoon {
        width: 255px;
      }
    }
  }
}


@media (max-width: 900px) {
  .feedback {
    margin-left: 0;

    .right-block {
      display: none;
    }
  }
}


@media (max-width: 800px) {
  .feedback{
    padding-top: 60px;
  }
}


@media (max-width: 500px) {
  .feedback{
   padding: 0;
    margin-bottom: 50px;

    .left-block{

      .rating-block{

        .title{
          //font-size: 1.4rem;
          margin: 45px 0;
        }
      }
    }
  }
}