li {
  list-style-type: none;
}

 .slide7-container{
   width: 100%;
   margin: 0 0 80px 260px;

   .slide7-wrapper{
     width: 100%;
     max-width: 100%;

     display: flex;
     align-items: center;

     .phone-slide7{
       width: 24%;

       .phone-screen-giftBonuses{
         width: 100%;

       }
     }

     .slide7-description{
       width: 40%;
       margin-left: 10%;

       color: var(--text-color);

       .slide7-firstDescription{
         margin-bottom: 20px;

         h3{
           margin-bottom: 20px;
           font-size: 1.3rem;
         }

         p{
           font-size: 19px;
           font-weight: 500;
         }

       }

       .slide7-secondDescription{

         p{
           font-size: 19px;
           font-weight: 500;
         }
       }
     }
   }
 }


@media (max-width: 1350px) {
  .slide7-container {
    margin: 0 0 80px 110px;

    .slide7-wrapper {

      .phone-slide7 {
        width: 35%;
      }

      .slide7-description {

        .slide7-firstDescription {

          h3 {
            font-size: 1.2rem;
          }

          p {
            font-size: 18px;
            font-weight: 450;
          }

        }

        .slide7-secondDescription {

          p {
            font-size: 18px;
            font-weight: 450;
          }
        }
      }
    }
  }
}


@media (max-width: 1050px) {
  .slide7-container {
    margin: 0;

    .slide7-wrapper {
      display: flex;
      flex-direction: column;
      min-height: auto;


      .phone-slide7 {
        width: 40%;
        margin-bottom: 20px;
      }

      .slide7-description {
        margin: 40px 0 30px 0;
        width: 50%;
      }
    }
  }
}


@media (max-width: 800px) {
  .slide7-container {

    .slide7-wrapper {

      .phone-slide7 {
        width: 55%;
      }

      .slide7-description {
        width: 80%;

        .slide7-firstDescription {

          h3 {
            font-size: 1.08rem;
          }

          p {
            font-size: 16px;
          }

        }

        .slide7-secondDescription {

          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .slide7-container {

    .slide7-wrapper {

      .phone-slide7 {
        width: 80%;
        margin-bottom: 0;
      }

      .slide7-description {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}


@media (max-width: 400px) {
  .slide7-container {

    .slide7-wrapper {

      .phone-slide7 {
        width: 90%;
      }

      .slide7-description {
        max-width: 200px;

      }
    }
  }
}